<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-card>
      <b-card-title>
        NOTIFICACIONES
      </b-card-title>
      <div class="">
        <app-timeline>
          <app-timeline-item
            v-for="(history,index) in histories"
            :key="index"
          >
            <div class="">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h3>{{ index }}</h3>
              </div>
              <div
                v-for="item in history"
                :key="item.id"
                class=""
              >
                <p>Codigo de cotización: <b>{{ item.quotationCode }}</b></p>
                <p>Asunto: <b>{{ item.subject }}</b></p>
                <p>Fecha: <b>{{ item.dateRegister }}</b></p>
                <p>
                  <!--              <span class="align-bottom">hace 1 mes</span>-->
                  <small class="text-muted">{{ item.created_format_humans }}</small>
                </p>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-end justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalHistories"
                per-page="10"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </div>
    </b-card>
  </b-overlay>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BPagination,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem'
import store from '@/store'
import {
  onMounted, onUnmounted, ref, watch,
} from '@vue/composition-api'
import notificationStoreModule from '@/views/dramox/notification/notificationStoreModule'

export default {
  components: {
    AppTimelineItem,
    AppTimeline,
    BCard,
    BRow,
    BCol,
    BPagination,
    BCardTitle,
    BOverlay,

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-notifications'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, notificationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const histories = ref({ })
    const currentPage = ref(1)
    const totalHistories = ref(0)
    const showOverlay = ref(false)

    const fetchHistories = ctx => {
      showOverlay.value = true
      store.dispatch('app-notifications/fetchNotifications', {
        page: currentPage.value,
      })
        .then(response => {
          const { data, total } = response.data.payload.results

          histories.value = data
          totalHistories.value = total
          showOverlay.value = false
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    watch([currentPage], () => {
      fetchHistories()
    })

    onMounted(() => {
      fetchHistories()
    })

    return {
      histories,

      currentPage,
      totalHistories,

      showOverlay,
    }
  },
}
</script>

<style scoped>

</style>
